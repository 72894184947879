// eslint-disable-next-line no-unused-vars
import React, { useContext, useState } from "react";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import { MenuContext } from "../../context/MenuContext";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import PropTypes from "prop-types";
/** @jsx jsx */
/* const borde = css({
  borderStyle: 'solid',
}); */

const MenuStyle = css({
  borderBottomStyle: "none",
  fontFamily: "Oxanium",
  backgroundColor: "transparent",
  fontWeight: "bold",
});

/* const ItemStyle = css({
  fontFamily: "Oxanium",
}); */

const Menus = ({ direction, ...args }) => {
  const { handleClick, current } = useContext(MenuContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      css={MenuStyle}
    >
      {/* <Menu.Item key="design">
        <Link to="/web-design">Servicios</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="contact">
        <Link to="/contacto">Comunícate con nosotros</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="mail-2">Facturación Electrónica</Menu.Item>
    <Menu.Item key="mail-3">Informática Forense</Menu.Item>*/}
      <Menu.Item key="services">
        {/* <div className="d-flex p-5"> */}
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
          <DropdownToggle caret color="#0000" style={{ fontWeight: "bold" }}>
            Servicios
          </DropdownToggle>
          <DropdownMenu {...args}>
            <DropdownItem onClick={() => history.push("/web-design")}>
              Programacíon de software
            </DropdownItem>
            <DropdownItem onClick={() => history.push("/expertise")}>
              Pericias Informáticas
            </DropdownItem>
            <DropdownItem onClick={() => history.push("/academic-advising")}>
              Asesoría Académica
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
};

Menus.propTypes = {
  direction: PropTypes.string,
};

export default Menus;
