// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/core";
import Loguito from "../../images/Loguito.png";
import { MenuContext } from "../../context/MenuContext";
/** @jsx jsx */

const Logo = () => {
  const { handleClick } = useContext(MenuContext);
  return (
    <div
      css={{
        paddingLeft: 25,
        paddingTop: 10,
        cursor: "pointer",
      }}
    >
      <Link to="/" onClick={() => handleClick("logo")}>
        <img src={Loguito} alt="log" height="70" width="200" />
      </Link>
    </div>
  );
};

export default Logo;
