// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/core";
import { Row, Container, Col } from "reactstrap";

/* const borde = css({
  borderStyle: 'solid',
}); */
const Footer = () => (
  <Row
    css={{
      backgroundColor: "#131921",
      color: "white",
      paddingTop: 50,
      paddingBottom: 50,
    }}
  >
    <Container css={{ fontSize: 12 }}>
      <Row className="justify-content-center" css={{ marginBottom: 10 }}>
        <Col lg={4} css={{ textAlign: "center" }}>
          Teléfonos: 0999080311 - 0979019824
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6} css={{ textAlign: "center" }}>
          Oficina: Calle Rumiñahui entre Av. Padre Chacón y Av. Puerta del
          Dorado,
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6} css={{ textAlign: "center" }}>
          Ciudad - Pelileo
        </Col>
      </Row>
    </Container>
  </Row>
);

export default Footer;
