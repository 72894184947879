// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import { css, jsx } from "@emotion/core";

import { WindowLocationContext } from "../../context/WindowLocationContext";
/** @jsx jsx */

const ItemStyle = css({
  fontFamily: "Oxanium",
  fontSize: 40,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 40,
  fontWeight: "bold",
});

const ItemStyle2 = css({
  fontFamily: "Oxanium",
  fontSize: 25,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 40,
  fontWeight: "bold",
});

const Expertise = () => {
  const { handleCurrentLocation } = useContext(WindowLocationContext);
  useEffect(() => {
    handleCurrentLocation(window.location.pathname);
  });
  return (
    <Container
      css={{
        marginTop: 120,
      }}
    >
      <Fade>
        <Row>
          <Col xs={12} lg={6}>
            <div css={ItemStyle}>Pericias Informáticas</div>
          </Col>
          <Col xs={12} lg={12}>
            <div css={ItemStyle2}>En construcción........</div>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};

export default Expertise;
