// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/core";
import { Row, Col } from "reactstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Logo from "./Logo";
import Menus from "./Menus";

/* const borde = css({
  borderStyle: 'solid',
}); */
const Header = () => {
  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      //const isShow = currPos.y > prevPos.y;

      if (currPos.y === 0) setHideOnScroll(true);
      if (currPos.y < 0) setHideOnScroll(false);
      //if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  return (
    <Row
      className="fixed-top"
      css={{
        // backgroundColor: "white"
        "-webkit-box-shadow": hideOnScroll
          ? "none"
          : "0px 0.5px 5px 0px rgba(0,0,0,0.75)",
        "-moz-box-shadow": hideOnScroll
          ? "none"
          : "0px 0.5px 5px 0px rgba(0,0,0,0.75)",
        "box-shadow": hideOnScroll
          ? "none"
          : "0px 0.5px 5px 0px rgba(0,0,0,0.75)",
        backgroundColor: hideOnScroll ? "transparent" : "white",
      }}
    >
      <Col xs={12} lg={3}>
        <Logo />
      </Col>
      <Col xs={12} lg={9} className="align-self-end">
        <Menus />
      </Col>
    </Row>
  );
};

export default Header;
