// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import { Row, Container, Col } from "reactstrap";
import { target } from "react-icons-kit/icomoon/target";
import { ic_important_devices } from "react-icons-kit/md/ic_important_devices";
import { coinDollar } from "react-icons-kit/icomoon/coinDollar";
import { right } from "react-icons-kit/entypo/right";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { css, jsx } from "@emotion/core";
import { MenuContext } from "../../context/MenuContext";
/** @jsx jsx */
/* const borde = css({
  borderStyle: 'solid',
}); */
const titleStyle = css({
  textAlign: "center",
  fontSize: 28,
  paddingTop: 50,
  paddingBottom: 30,
  fontWeight: "bold",
});
const servicesStyle = css({
  textAlign: "center",
  fontSize: 17,
  fontWeight: "bold",
});
const servicesDescriptionStyle = css({
  fontSize: 15,
  fontWeight: "normal",
});
const Services = () => {
  const { handleClick } = useContext(MenuContext);
  return (
    <Row
      css={{
        backgroundColor: "#232F3E",
        color: "white",
        fontFamily: "Oxanium",
        paddingBottom: "",
      }}
    >
      <Container>
        <Col css={titleStyle}>Que servicios te brindamos ?</Col>
      </Container>

      <Container css={servicesStyle}>
        <Row>
          <Col xs={12} lg={6}>
            <Row>
              <Container>
                <Col xs={12} css={{ paddingTop: 10, paddingBottom: 15 }}>
                  <Icon size={65} icon={ic_important_devices} />
                </Col>
                <Col xs={12} css={{ paddingBottom: 25 }}>
                  Diseño y Programación de Aplicaciones WEB
                </Col>
                <Col xs={12} css={servicesDescriptionStyle}>
                  <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Diseñamos y programamos aplicaciones web de alta calidad,
                    desde cero. Nos aseguramos de que cada aspecto de tu
                    proyecto esté bien cuidado, desde la programación de
                    plantillas y bases de datos hasta la administración y
                    gestión de la infraestructura en la nube. Deja que nuestro
                    equipo de expertos te ayude a llevar tu negocio al siguiente
                    nivel.
                  </p>
                </Col>
                <Col xs={12} css={{ paddingTop: 10, paddingBottom: 15 }}>
                  <Link
                    to="/web-design"
                    onClick={() => handleClick({ key: "design" })}
                  >
                    <div>
                      Leer más <Icon size={15} icon={right} />
                    </div>
                  </Link>
                </Col>
              </Container>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Container>
                <Col xs={12} css={{ paddingTop: 10, paddingBottom: 15 }}>
                  <Icon size={65} icon={target} />
                </Col>
                <Col xs={12} css={{ paddingBottom: 25 }}>
                  Informática Forense
                </Col>
                <Col xs={12} css={servicesDescriptionStyle}>
                  <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Somos peritos judiciales acreditados en el área de
                    criminalística, especialidad en informática forense. Con más
                    de 10 años de experiencia, hemos trabajado con éxito en
                    diversos casos, incluyendo difamaciones en redes sociales,
                    análisis de sistemas de instituciones financieras y de
                    salud, y extracción y recuperación de información en
                    computadoras y celulares. Si necesitas soporte técnico
                    informático para tus procesos jurídicos, nuestro equipo de
                    expertos está a tu disposición.
                  </p>
                  {/* <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Hemos trabajado con éxito en diversos procesos judiciales e
                    investigaciones de la Fiscalía General del Estado, como:
                    Difamaciones a través de redes sociales, transcripción de
                    audio y video, análisis de sistemas de instituciones
                    financieras y de salud, extracción y recuperación de
                    información en computadoras y celulares.
                  </p> */}

                  {/* <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Somos tu soporte técnico informático en procesos jurídicos.
                  </p> */}
                </Col>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <Row css={servicesStyle}>
              <Container>
                <Col xs={12} css={{ paddingTop: 10, paddingBottom: 15 }}>
                  <Icon size={65} icon={coinDollar} />
                </Col>
                <Col xs={12} css={{ paddingBottom: 25 }}>
                  Soluciones Contables
                </Col>
                <Col xs={12} css={servicesDescriptionStyle}>
                  <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Entendemos que llevar la contabilidad de tu negocio puede
                    ser abrumador. Por eso, ofrecemos asesoría y soluciones
                    contables para ayudarte a mantener tus finanzas organizadas
                    y al día. Ya sea que tengas un emprendimiento, una pequeña o
                    mediana empresa, o brindes servicios profesionales, nuestro
                    equipo de profesionales acreditados como peritos en el
                    Consejo de la Judicatura, en el área de contabilidad y sus
                    diferentes especialidades, está aquí para ayudarte a
                    alcanzar tus metas financieras.
                  </p>
                  {/* <p
                    css={{
                      textJustify: "auto",
                      textAlign: "justify",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                    }}
                  >
                    Contamos con profesionales acreditados como Peritos en el
                    Consejo de la Judicatura, en el área de contabilidad y sus
                    diferentes especialidades.
                  </p> */}
                </Col>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default Services;
