// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
//import gql from "graphql-tag";
//import { useMutation } from "@apollo/react-hooks";
import { useMutation, gql } from "@apollo/client";
import { message } from "antd";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const formStyle = css({
  /* borderStyle: "solid", */
  borderWidth: 1,
  padding: 10,
  borderRadius: 5,
});

// const inputStyle = css({
//   borderRadius: 5,
//   // padding: 3
// });

export const CONTACT = gql`
  mutation Contact($detail: String, $mail: String, $name: String) {
    contact(mail: $mail, name: $name, detail: $detail) {
      message
    }
  }
`;

export const ContactPageForm = () => {
  const [contact, { loading }] = useMutation(CONTACT);
  const [nameInput, setnameInput] = useState("");
  const [mailInput, setmailInput] = useState("");
  const [detailInput, setdetailInput] = useState("");

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "mail") {
      setmailInput(value);
    }
    if (name === "name") {
      setnameInput(value);
    }
    if (name === "detail") {
      setdetailInput(value);
    }
  };

  if (loading) return <p>Enviando....</p>;
  return (
    <div>
      <Row className="justify-content-center">
        <Col
          lg={3}
          css={{
            textAlign: "center",
            fontSize: 15,
            fontWeight: "bold",
            paddingBottom: 15,
            paddingTop: 20,
          }}
        >
          Conumícate con nosotros
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5}>
          <div
            css={{
              textAlign: "justify",
              fontSize: 30,
              fontWeight: "bold",
              color: "black",
              paddingBottom: 15,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            Nuestros expertos están aquí para responder tus preguntas
          </div>
        </Col>
      </Row>
      <Row css={{ marginBottom: 25 }} className="justify-content-center">
        <Col lg={4}>
          <Form
            css={formStyle}
            onSubmit={(e) => {
              e.preventDefault();

              contact({
                variables: {
                  name: nameInput,
                  mail: mailInput,
                  detail: detailInput,
                },
              })
                // eslint-disable-next-line no-unused-vars
                .then((data) => {
                  // console.log("el data : ", data.contact.message);
                  message.success("En breve nos pondremos en contacto contigo");
                })
                // eslint-disable-next-line no-unused-vars
                .catch((e) => {
                  // message.error(
                  //   e.graphQLErrors.map(({ message }) => {
                  //     return message;
                  //   })
                  // );
                  message.error(
                    "Ha ocurrido un error con el envio de tus datos"
                  );
                });
              setmailInput("");
              setnameInput("");
              setdetailInput("");
            }}
          >
            <FormGroup>
              <Label for="mail">Tu correo:</Label>
              <Input
                id="mail"
                required
                name="mail"
                value={mailInput}
                onChange={handleChange}
                bsSize="sm"
                type="email"
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Nombre:</Label>
              <Input
                id="name"
                required
                name="name"
                value={nameInput}
                onChange={handleChange}
                bsSize="sm"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label for="detail">Pregunta :</Label>
              <Input
                id="detail"
                required
                name="detail"
                value={detailInput}
                onChange={handleChange}
                bsSize="sm"
                type="textarea"
              />
            </FormGroup>
            <Button
              type="primary"
              htmltype="submit"
              css={{
                backgroundColor: "#E0A806",
                borderColor: "#E0A806",
                color: "black",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

//export default ContactPageForm;
