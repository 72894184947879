// eslint-disable-next-line no-unused-vars
import React from "react";
import { Row, Container, Col } from "reactstrap";
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/core";
import tukanasta from "../../images/tukanasta.png";
import logo_tukanasta from "../../images/logo_tukanasta.png";
import cauca_logo from "../../images/cauca_logo.png";
import cauca_preview from "../../images/cauca_preview.png";
import scfi from "../../images/scfi.png";
import sistemas247 from "../../images/sistemas247.png";
import aulas from "../../images/aulas.png";
/** @jsx jsx */

const Products = () => (
  <div css={{ fontFamily: "Oxanium", paddingLeft: 20 }}>
    <Container>
      <Row
        className="justify-content-center"
        css={{ fontWeight: "bold", fontSize: 25, paddingBottom: 20 }}
      >
        Nuestros Productos
      </Row>
    </Container>
    <Row css={{ fontWeight: "bold", fontSize: 15, paddingBottom: 30 }}>
      Tiendas en línea
    </Row>
    <Row css={{ paddingBottom: 50 }}>
      <Col xs={12} lg={5}>
        <Row>
          <img src={cauca_logo} alt="log" height="40" width="130" />{" "}
          {/* <a
            href="http://cauca.ingeniodc.com"
            // eslint-disable-next-line
            target="_blank"
            rel="noreferrer"
            css={{ paddingLeft: 20, fontWeight: "bold" }}
          >
            Visitar
          </a> */}
        </Row>
        <Row>
          <div css={{ paddingTop: 20 }}>
            Tienda en línea para promover el mercado interno y externo de la
            Ciudad de Pelileo
          </div>
        </Row>
      </Col>
      <Col>
        <img src={cauca_preview} alt="log" height="350" width="620" />
      </Col>
    </Row>
    <Row css={{ paddingBottom: 50 }}>
      <Col xs={12} lg={5}>
        <Row>
          <img src={logo_tukanasta} alt="log" height="80" width="150" />{" "}
          {/* <a
            href="https://www.tukanasta.com"
            // eslint-disable-next-line
            target="_blank"
            css={{ paddingLeft: 20, fontWeight: "bold" }}
            rel="noreferrer"
          >
            Visitar
          </a> */}
        </Row>
        <Row>
          <div css={{ paddingTop: 20 }}>
            Tienda en línea para suministrar productos 100% naturales a la
            Ciudad de Ambato
          </div>
        </Row>
      </Col>
      <Col>
        <img src={tukanasta} alt="log" height="350" width="620" />
      </Col>
    </Row>
    <Row css={{ fontWeight: "bold", fontSize: 15, paddingBottom: 20 }}>
      Puntos de Venta
    </Row>
    <Row css={{ marginBottom: 50 }}>
      <Col xs={12} lg={5}>
        <Row css={{ fontWeight: "bold", paddingBottom: 10 }}>
          Sistema Contable y de Facturación Electrónica
          {/* <a
            href="http://scfi.ingeniodc.com"
            // eslint-disable-next-line
            target="_blank"
            css={{ marginLeft: 10 }}
            rel="noreferrer"
          >
            Visitar
          </a> */}
        </Row>
        <Row>
          <p>
            Punto de Ventas en línea, donde los emprendedores podrán llevar un
            inventario de sus productos, además del control de sus ventas.{" "}
          </p>
          {/* <p>Puedes probar nuestro producto con las siguientes credenciales:</p>
          <ul>
            <li>
              <div>
                usuario: <b>jefeventas@dev.org</b>
              </div>
            </li>
            <li>
              <div>
                contraseña: <b>12345</b>
              </div>
            </li>
          </ul> */}
        </Row>
      </Col>
      <Col>
        <img src={scfi} alt="log" height="200" width="620" />
      </Col>
    </Row>
    <Row css={{ marginBottom: 50, marginTop: 100 }}>
      <Col xs={12} lg={5}>
        <Row css={{ fontWeight: "bold", paddingBottom: 10 }}>
          {/* En desarrollo!
          <a
            href="http://www.sistemas247.com"
            // eslint-disable-next-line
            target="_blank"
            css={{ marginLeft: 10 }}
            rel="noreferrer"
          >
            Visitar
          </a> */}
        </Row>
        <Row>
          <p>
            Página para emprendimiento de servicios profesionales informáticos
          </p>
        </Row>
      </Col>
      <Col>
        <img src={sistemas247} alt="log" height="350" width="620" />
      </Col>
    </Row>
    <Row css={{ marginBottom: 50, marginTop: 100 }}>
      <Col xs={12} lg={5}>
        <Row css={{ fontWeight: "bold", paddingBottom: 10 }}>
          Aulas Virtuales:
          {/* <a
            href="http://aulas.ingeniodc.com"
            // eslint-disable-next-line
            target="_blank"
            css={{ marginLeft: 10 }}
            rel="noreferrer"
          >
            Visitar
          </a> */}
        </Row>
        <Row>
          <p>
            Brindamos soporte a profesionales e instituciones educativas,
            desplegando la plataforma de aulas virtuales (moodle) en la nube.
            Además contamos con expertos en la gestión y administración de
            entornos de aprendizaje virtuales.
          </p>
        </Row>
      </Col>
      <Col>
        <img src={aulas} alt="log" height="350" width="620" />
      </Col>
    </Row>
  </div>
);

export default Products;
