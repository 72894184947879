import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { MenuProvider } from "./context/MenuContext";
import { WindowLocationProvider } from "./context/WindowLocationContext";
import { ApolloClient, InMemoryCache } from "@apollo/client";

// eslint-disable-next-line no-undef
require("dotenv").config();

const client = new ApolloClient({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_API_GRAPHQL,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <WindowLocationProvider>
    <MenuProvider>
      <App client={client} />
    </MenuProvider>
  </WindowLocationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
