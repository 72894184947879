import React from "react";
import PropTypes from "prop-types";
const WindowLocationContext = React.createContext();

class WindowLocationProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: null,
    };
  }

  handleCurrentLocation = (location) => {
    const { currentLocation } = this.state;
    if (currentLocation !== location) {
      // console.log('click ', e);
      this.setState({
        currentLocation: location,
      });
    }
  };

  render() {
    return (
      <WindowLocationContext.Provider
        value={{
          currentLocation: this.state.currentLocation,
          handleCurrentLocation: this.handleCurrentLocation,
        }}
      >
        <div>{this.props.children}</div>
      </WindowLocationContext.Provider>
    );
  }
}

WindowLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WindowLocationProvider, WindowLocationContext };
