// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Row } from "reactstrap";
//import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "@apollo/client";
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/core";
import Header from "./components/statics/Header";
import Home from "./components/statics/Home";
import Webdesign from "./components/WebDesign/index";
import Expertise from "./components/Expertise";
import AcademicAdvising from "./components/AcademicAdvising";
import ContactPage from "./components/ContactPage/index";
//import fondo_sandry from "./images/fondo_sandry.png";
import Footer from "./components/statics/Footer";
import PropTypes from "prop-types";

import { WindowLocationContext } from "./context/WindowLocationContext";

/** @jsx jsx */

function App({ client }) {
  const { currentLocation } = useContext(WindowLocationContext);

  return (
    <ApolloProvider client={client}>
      <Router>
        <Row
          css={{
            background: currentLocation === "/" && "rgba(179,220,237,1)",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "-moz-linear-gradient(left, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 99%, rgba(188,224,238,1) 100%)",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "-webkit-gradient(left top, right top, color-stop(0%, rgba(179,220,237,1)), color-stop(99%, rgba(41,184,229,1)), color-stop(100%, rgba(188,224,238,1)))",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "-webkit-linear-gradient(left, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 99%, rgba(188,224,238,1) 100%)",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "-o-linear-gradient(left, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 99%, rgba(188,224,238,1) 100%)",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "-ms-linear-gradient(left, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 99%, rgba(188,224,238,1) 100%)",
            // eslint-disable-next-line no-dupe-keys
            background:
              currentLocation === "/" &&
              "linear-gradient(to right, rgba(179,220,237,1) 0%, rgba(41,184,229,1) 99%, rgba(188,224,238,1) 100%)",
            filter:
              currentLocation === "/" &&
              "progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3dced', endColorstr='#bce0ee', GradientType=1 )",
          }}
        >
          <Header />
          <Switch>
            <Route path="/academic-advising">
              <AcademicAdvising />
            </Route>
            <Route path="/expertise">
              <Expertise />
            </Route>
            <Route path="/web-design">
              <Webdesign />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Row>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

App.propTypes = {
  client: PropTypes.object.isRequired,
};

export default App;
