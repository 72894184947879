// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Row, Container, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import Celular from "../../images/Celular.png";
import Services from "../Home/Services";

import { WindowLocationContext } from "../../context/WindowLocationContext";

const ItemStyle = css({
  fontFamily: "Oxanium",
  fontSize: 60,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  fontWeight: "bold",
});
const Home = () => {
  const { handleCurrentLocation } = useContext(WindowLocationContext);
  useEffect(() => {
    handleCurrentLocation(window.location.pathname);
  });
  return (
    <div
      css={{
        marginTop: 110,
      }}
    >
      <Fade bottom>
        <Row>
          <Container
            css={{
              color: "black",
              paddingBottom: 80,
              paddingTop: 50,
            }}
          >
            <Row>
              <Col xs={12} lg={6}>
                <div css={ItemStyle}>
                  En Ingeniodc hacemos que tus ideas cobren vida
                </div>
                <div
                  css={{
                    fontSize: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontFamily: "Oxanium",
                    fontWeight: "bold",
                  }}
                >
                  Nuestro equipo de profesionales trabaja para hacer de tus
                  ideas un producto tangible y real.
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <Row className="justify-content-center">
                  <img src={Celular} alt="log" height="350" width="380" />
                </Row>
              </Col>
            </Row>
          </Container>
        </Row>
      </Fade>
      <Services />
    </div>
  );
};

export default Home;
