import React from "react";
import PropTypes from "prop-types";
const MenuContext = React.createContext();

class MenuProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
    };
  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (
      <MenuContext.Provider
        value={{
          current: this.state.current,
          handleClick: this.handleClick,
        }}
      >
        <div>{this.props.children}</div>
      </MenuContext.Provider>
    );
  }
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MenuProvider, MenuContext };
